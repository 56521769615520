import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import Airtable from 'airtable'
import Text from '../../common/Text'
import Space from '../../common/Space'
import { persistData, getPersistedData } from '../../../utils/localStorageHelpers'

// Airtable columns
const labelColName = 'Label'

// BS table columns
const june2021ColName = 'June 30, 2021'
const dec2020ColName = 'December 31, 2020'

// IS/Non-GAAP table columns
const threeMonth2021ColName = 'Three Months 2021'
const threeMonth2020ColName = 'Three Months 2020'
const sixMonth2021ColName = 'Six Months 2021'
const sixMonth2020ColName = 'Six Months 2020'

// configure Airtable
Airtable.configure({
  endpointUrl: 'https://api.airtable.com',
  apiKey: process.env.GATSBY_AIRTABLE_API_KEY,
})
const AIRTABLE_BASE = Airtable.base('appbR2ijhY6i2qNYu')

const StyledTable = styled.table`
  width: 100%;
  font-family: ${p => p.theme.fonts.family.body};
  margin: 0 auto;

  caption {
    font-weight: 700;
    margin-bottom: 1.5em;
  }
  .text-weight-book {
    font-weight: 400 !important;
  }
  td,
  th {
    text-align: left;
    border-bottom: solid 1px gray;
    padding: 0.3em;
    /* width: 100%; */
  }
  td.w-60,
  th.w-60 {
    width: 60%;
  }
  td.text-align-right,
  th.text-align-right {
    text-align: right;
  }
  td.text-align-center,
  th.text-align-center {
    text-align: center;
  }
  .text-header {
    font-weight: 400;
  }

  .large-header {
    font-size: 1.2em;
  }
  .indent {
    padding-left: 1em;
  }
  .mr-2 {
    margin-right: 1em;
  }
  .mt-2 {
    margin-top: 1em;
  }
  .mt-4 {
    margin-top: 2em;
  }
  .border-top {
    border-top: solid 1px black;
  }
  .no-border {
    border: none;
  }
  .subtotal,
  .total,
  .total2 {
    position: relative;
  }
  .subtotal:before {
    content: '';
    background: black;
    position: absolute;
    top: -4px;
    right: 0;
    height: 1px;
    width: 75%;
  }
  .subtotal.full-width:before {
    width: 100%;
  }
  .subtotal.space-between {
    display: flex;

    div {
      width: 50%;
      text-align: right;
      border-bottom: 1px solid black;

      &:not(:first-of-type) {
        margin-left: 12px;
      }
    }
  }
  .total:after {
    content: '';
    background: black;
    position: absolute;
    bottom: -4px;
    right: 0;
    height: 1px;
    width: 75%;
  }
  .total2:after {
    content: '';
    background: black;
    position: absolute;
    bottom: -7px;
    right: 0;
    height: 1px;
    width: 75%;
  }
  .spacer {
    padding: 0.8em;
  }
  .spacer-row-subtotal {
    .subtotal {
      height: 19px;
    }
  }
  .no-wrap {
    white-space: nowrap;
  }
`

const formatCurrency = currency =>
  currency ? currency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : <span>&mdash;</span>

const IsTable = data => {
  const isData = data.data

  return (
    <StyledTable
      summary="Column one has the revenue source, other columns show the amounts by date period"
      id="ae_revenue"
      className="financial-table"
      border="0"
      cellpadding="0"
      cellspacing="0"
    >
      <thead>
        <tr>
          <td className="no-border" />
          <th colSpan="2" id="threemonths" scope="col" className="text-align-center no-wrap no-border">
            Three months ended
            <br />
            June 30,
          </th>
          <th colSpan="2" id="sixmonths" scope="col" className="text-align-center no-wrap no-border">
            Six months ended
            <br />
            June 30,
          </th>
        </tr>
        <tr>
          <td className="no-border" />
          <th id="3m2021" scope="col" colSpan="2" className="no-border no-wrap">
            <div className="subtotal full-width space-between">
              <div style={{ textAlign: 'center' }}>2021</div>
              <div style={{ textAlign: 'center' }}>2020</div>
            </div>
          </th>
          <th id="6m2021" scope="col" colSpan="2" className="no-border no-wrap">
            <div className="subtotal full-width space-between">
              <div style={{ textAlign: 'center' }}>2021</div>
              <div style={{ textAlign: 'center' }}>2020</div>
            </div>
          </th>
        </tr>
        <tr>
          <td colSpan="5" className="text-align-left">
            ( In thousands, except per share data )
          </td>
        </tr>
      </thead>
      <tbody>
        {isData.Revenue && (
          <tr>
            <th id="revenue" className="text-header">
              Revenue
            </th>
            <td headers="threemonths 3m2021 revenue" className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(isData.Revenue[threeMonth2021ColName])}
            </td>
            <td headers="threemonths 3m2020 revenue" className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(isData.Revenue[threeMonth2020ColName])}
            </td>
            <td headers="sixmonths 6m2021 revenue" className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(isData.Revenue[sixMonth2021ColName])}
            </td>
            <td headers="sixmonths 6m2020 revenue" className="text-align-right">
              <span className="mr-2">$</span>
              {/* {formatCurrency(isData.Revenue[sixMonth2020ColName])} */}
              {formatCurrency(isData.Revenue[sixMonth2020ColName])}
            </td>
          </tr>
        )}

        {isData['Cost of revenue'] && (
          <tr>
            <th className="text-header">Cost of revenue</th>
            <td headers="threemonths 3m2021 costofrevenue" className="text-align-right no-border">
              {formatCurrency(isData['Cost of revenue'][threeMonth2021ColName])}
            </td>
            <td headers="threemonths 3m2020 costofrevenue" className="text-align-right no-border">
              {formatCurrency(isData['Cost of revenue'][threeMonth2020ColName])}
            </td>
            <td headers="sixmonths 6m2021 costofrevenue" className="text-align-right no-border">
              {formatCurrency(isData['Cost of revenue'][sixMonth2021ColName])}
            </td>
            <td headers="sixmonths 6m2020 costofrevenue" className="text-align-right no-border">
              {formatCurrency(isData['Cost of revenue'][sixMonth2020ColName])}
            </td>
          </tr>
        )}

        <tr className="spacer-row-subtotal">
          <td role="presentation" />
          <td role="presentation">
            <div className="subtotal">&nbsp;</div>
          </td>
          <td role="presentation">
            <div className="subtotal">&nbsp;</div>
          </td>
          <td role="presentation">
            <div className="subtotal">&nbsp;</div>
          </td>
          <td role="presentation">
            <div className="subtotal">&nbsp;</div>
          </td>
        </tr>

        {isData['Gross profit'] && (
          <tr>
            <th className="text-header">Gross profit</th>
            <td headers="threemonths 3m2021 grossprofit" className="text-align-right">
              {formatCurrency(isData['Gross profit'][threeMonth2021ColName])}
            </td>
            <td headers="threemonths 3m2020 grossprofit" className="text-align-right">
              {formatCurrency(isData['Gross profit'][threeMonth2020ColName])}
            </td>
            <td headers="sixmonths 6m2021 grossprofit" className="text-align-right">
              {formatCurrency(isData['Gross profit'][sixMonth2021ColName])}
            </td>
            <td headers="sixmonths 6m2020 grossprofit" className="text-align-right">
              {formatCurrency(isData['Gross profit'][sixMonth2020ColName])}
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer" />
        </tr>
        <tr>
          <th id="oe" colSpan="5" scope="colgroup" className="text-header">
            Operating expenses:
          </th>
        </tr>

        {isData['Selling and marketing'] && (
          <tr>
            <th id="sam" className="text-header">
              Selling and marketing
            </th>
            <td headers="threemonths 3m2021 sam" className="text-align-right">
              {formatCurrency(isData['Selling and marketing'][threeMonth2021ColName])}
            </td>
            <td headers="threemonths 3m2020 sam" className="text-align-right">
              {formatCurrency(isData['Selling and marketing'][threeMonth2020ColName])}
            </td>
            <td headers="sixmonths 6m2021 sam" className="text-align-right">
              {formatCurrency(isData['Selling and marketing'][sixMonth2021ColName])}
            </td>
            <td headers="sixmonths 6m2020 sam" className="text-align-right">
              {formatCurrency(isData['Selling and marketing'][sixMonth2020ColName])}
            </td>
          </tr>
        )}

        {isData['Research and development'] && (
          <tr>
            <th id="rad" className="text-header">
              Research and development
            </th>
            <td headers="threemonths 3m2021 rad" className="text-align-right">
              {formatCurrency(isData['Research and development'][threeMonth2021ColName])}
            </td>
            <td headers="threemonths 3m2020 rad" className="text-align-right">
              {formatCurrency(isData['Research and development'][threeMonth2020ColName])}
            </td>
            <td headers="sixmonths 6m2021 rad" className="text-align-right">
              {formatCurrency(isData['Research and development'][sixMonth2021ColName])}
            </td>
            <td headers="sixmonths 6m2020 rad" className="text-align-right">
              {formatCurrency(isData['Research and development'][sixMonth2020ColName])}
            </td>
          </tr>
        )}

        {isData['General and administrative'] && (
          <tr>
            <th id="ga" className="text-header">
              General and administrative
            </th>
            <td headers="threemonths 3m2021" className="text-align-right no-border">
              {formatCurrency(isData['General and administrative'][threeMonth2021ColName])}
            </td>
            <td headers="threemonths 3m2020" className="text-align-right no-border">
              {formatCurrency(isData['General and administrative'][threeMonth2020ColName])}
            </td>
            <td headers="sixmonths 6m2021" className="text-align-right no-border">
              {formatCurrency(isData['General and administrative'][sixMonth2021ColName])}
            </td>
            <td headers="sixmonths 6m2020" className="text-align-right no-border">
              {formatCurrency(isData['General and administrative'][sixMonth2020ColName])}
            </td>
          </tr>
        )}

        {isData['Total operating expenses'] && (
          <tr>
            <th id="toe" className="text-header">
              Total operating expenses
            </th>
            <td headers="threemonths 3m2021" className="text-align-right">
              <div className="subtotal">
                {formatCurrency(isData['Total operating expenses'][threeMonth2021ColName])}
              </div>
            </td>
            <td headers="threemonths 3m2020" className="text-align-right">
              <div className="subtotal">
                {formatCurrency(isData['Total operating expenses'][threeMonth2020ColName])}
              </div>
            </td>
            <td headers="sixmonths 6m2021" className="text-align-right">
              <div className="subtotal">{formatCurrency(isData['Total operating expenses'][sixMonth2021ColName])}</div>
            </td>
            <td headers="sixmonths 6m2020" className="text-align-right">
              <div className="subtotal">{formatCurrency(isData['Total operating expenses'][sixMonth2020ColName])}</div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer" />
        </tr>

        {isData['Operating loss'] && (
          <tr>
            <th id="ol" className="text-header">
              Operating loss
            </th>
            <td headers="threemonths 3m2021" className="text-align-right" aria-label="negative value">
              ({formatCurrency(isData['Operating loss'][threeMonth2021ColName])})
            </td>
            <td headers="threemonths 3m2020" className="text-align-right" aria-label="negative value">
              ({formatCurrency(isData['Operating loss'][threeMonth2020ColName])})
            </td>
            <td headers="sixmonths 6m2021" className="text-align-right" aria-label="negative value">
              ({formatCurrency(isData['Operating loss'][sixMonth2021ColName])})
            </td>
            <td headers="sixmonths 6m2020" className="text-align-right" aria-label="negative value">
              ({formatCurrency(isData['Operating loss'][sixMonth2020ColName])})
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer" />
        </tr>

        <tr>
          <th id="oie" colSpan="5" scope="colgroup" className="text-header">
            Other income (expense):
          </th>
        </tr>

        {isData['Change in fair value of warrant liability'] && (
          <tr>
            <th id="ol" className="text-header">
              Change in fair value of warrant liability
            </th>
            <td headers="threemonths 3m2021" className="text-align-right">
              {formatCurrency(isData['Change in fair value of warrant liability'][threeMonth2021ColName])}
            </td>
            <td headers="threemonths 3m2020" className="text-align-right">
              ({formatCurrency(isData['Change in fair value of warrant liability'][threeMonth2020ColName])})
            </td>
            <td headers="sixmonths 6m2021" className="text-align-right">
              {formatCurrency(isData['Change in fair value of warrant liability'][sixMonth2021ColName])}
            </td>
            <td headers="sixmonths 6m2020" className="text-align-right">
              ({formatCurrency(isData['Change in fair value of warrant liability'][sixMonth2020ColName])})
            </td>
          </tr>
        )}

        {isData['Gain on loan forgiveness'] && (
          <tr>
            <th id="ol" className="text-header">
              Gain on loan forgiveness
            </th>
            <td headers="threemonths 3m2021" className="text-align-right">
              {formatCurrency(isData['Gain on loan forgiveness'][threeMonth2021ColName])}
            </td>
            <td headers="threemonths 3m2020" className="text-align-right">
              {formatCurrency(isData['Gain on loan forgiveness'][threeMonth2020ColName])}
            </td>
            <td headers="sixmonths 6m2021" className="text-align-right">
              {formatCurrency(isData['Gain on loan forgiveness'][sixMonth2021ColName])}
            </td>
            <td headers="sixmonths 6m2020" className="text-align-right">
              {formatCurrency(isData['Gain on loan forgiveness'][sixMonth2020ColName])}
            </td>
          </tr>
        )}

        {isData['Interest expense'] && (
          <tr>
            <th id="iein" className="text-header">
              Interest expense
            </th>
            <td headers="threemonths 3m2021" className="text-align-right no-border" aria-label="negative value">
              ({formatCurrency(isData['Interest expense'][threeMonth2021ColName])})
            </td>
            <td headers="threemonths 3m2020" className="text-align-right no-border" aria-label="negative value">
              ({formatCurrency(isData['Interest expense'][threeMonth2020ColName])})
            </td>
            <td headers="sixmonths 6m2021" className="text-align-right no-border" aria-label="negative value">
              ({formatCurrency(isData['Interest expense'][sixMonth2021ColName])})
            </td>
            <td headers="sixmonths 6m2020" className="text-align-right no-border" aria-label="negative value">
              ({formatCurrency(isData['Interest expense'][sixMonth2020ColName])})
            </td>
          </tr>
        )}

        {isData['Total other income (expense)'] && (
          <tr>
            <th id="toe" className="text-header">
              Total other income (expense)
            </th>
            <td headers="threemonths 3m2021" className="text-align-right">
              <div className="subtotal">
                {formatCurrency(isData['Total other income (expense)'][threeMonth2021ColName])}
              </div>
            </td>
            <td headers="threemonths 3m2020" className="text-align-right" aria-label="negative value">
              <div className="subtotal">
                ({formatCurrency(isData['Total other income (expense)'][threeMonth2020ColName])})
              </div>
            </td>
            <td headers="sixmonths 6m2021" className="text-align-right" aria-label="negative value">
              <div className="subtotal">
                {formatCurrency(isData['Total other income (expense)'][sixMonth2021ColName])}
              </div>
            </td>
            <td headers="sixmonths 6m2020" className="text-align-right" aria-label="negative value">
              <div className="subtotal">
                ({formatCurrency(isData['Total other income (expense)'][sixMonth2020ColName])})
              </div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer" />
        </tr>

        {isData['Net loss'] && (
          <tr>
            <th id="nl" className="text-header">
              Net loss
            </th>
            <td headers="threemonths 3m2021" className="text-align-right" aria-label="negative value">
              ({formatCurrency(isData['Net loss'][threeMonth2021ColName])})
            </td>
            <td headers="threemonths 3m2020" className="text-align-right" aria-label="negative value">
              ({formatCurrency(isData['Net loss'][threeMonth2020ColName])})
            </td>
            <td headers="sixmonths 6m2021" className="text-align-right" aria-label="negative value">
              ({formatCurrency(isData['Net loss'][sixMonth2021ColName])})
            </td>
            <td headers="sixmonths 6m2020" className="text-align-right" aria-label="negative value">
              ({formatCurrency(isData['Net loss'][sixMonth2020ColName])})
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer no-border" />
        </tr>

        {isData['Dividends on Series A Convertible Preferred Stock'] && (
          <tr>
            <th id="dosacps" className="text-header no-border">
              Dividends on Series A Convertible Preferred Stock
            </th>
            <td headers="threemonths 3m2021" className="text-align-right no-border" aria-label="negative value">
              <div className="total">
                ({formatCurrency(isData['Dividends on Series A Convertible Preferred Stock'][threeMonth2021ColName])})
              </div>
            </td>
            <td headers="threemonths 3m2020" className="text-align-right no-border" aria-label="negative value">
              <div className="total">
                ({formatCurrency(isData['Dividends on Series A Convertible Preferred Stock'][threeMonth2020ColName])})
              </div>
            </td>
            <td headers="sixmonths 6m2021" className="text-align-right no-border" aria-label="negative value">
              <div className="total">
                ({formatCurrency(isData['Dividends on Series A Convertible Preferred Stock'][sixMonth2021ColName])})
              </div>
            </td>
            <td headers="sixmonths 6m2020" className="text-align-right no-border" aria-label="negative value">
              <div className="total">
                ({formatCurrency(isData['Dividends on Series A Convertible Preferred Stock'][sixMonth2020ColName])})
              </div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer no-border" />
        </tr>

        {isData['Net loss available to common stockholders'] && (
          <tr>
            <th id="nlatcs" className="text-header no-border">
              Net loss available to common stockholders
            </th>
            <td headers="threemonths 3m2021" className="text-align-right no-border" aria-label="negative value">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>(
                  {formatCurrency(isData['Net loss available to common stockholders'][threeMonth2021ColName])})
                </div>
              </div>
            </td>
            <td headers="threemonths 3m2020" className="text-align-right no-border" aria-label="negative value">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>(
                  {formatCurrency(isData['Net loss available to common stockholders'][threeMonth2020ColName])})
                </div>
              </div>
            </td>
            <td headers="sixmonths 6m2021" className="text-align-right no-border" aria-label="negative value">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>(
                  {formatCurrency(isData['Net loss available to common stockholders'][sixMonth2021ColName])})
                </div>
              </div>
            </td>
            <td headers="sixmonths 6m2020" className="text-align-right no-border" aria-label="negative value">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>(
                  {formatCurrency(isData['Net loss available to common stockholders'][sixMonth2020ColName])})
                </div>
              </div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer no-border" />
        </tr>

        {isData['Net loss per common share-basic and diluted'] && (
          <tr>
            <th id="nlpcsad" className="text-header no-border">
              Net loss per common share-basic and diluted
            </th>
            <td headers="threemonths 3m2021" className="text-align-right no-border" aria-label="negative value">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>(
                  {formatCurrency(isData['Net loss per common share-basic and diluted'][threeMonth2021ColName])})
                </div>
              </div>
            </td>
            <td headers="threemonths 3m2020" className="text-align-right no-border" aria-label="negative value">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>(
                  {formatCurrency(isData['Net loss per common share-basic and diluted'][threeMonth2020ColName])})
                </div>
              </div>
            </td>
            <td headers="sixmonths 6m2021" className="text-align-right no-border" aria-label="negative value">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>(
                  {formatCurrency(isData['Net loss per common share-basic and diluted'][sixMonth2021ColName])})
                </div>
              </div>
            </td>
            <td headers="sixmonths 6m2020" className="text-align-right no-border" aria-label="negative value">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>(
                  {formatCurrency(isData['Net loss per common share-basic and diluted'][sixMonth2020ColName])})
                </div>
              </div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer no-border" />
        </tr>

        {isData['Weighted average common shares outstanding-basic and diluted'] && (
          <tr>
            <th id="wacsoad" className="text-header no-border">
              Weighted average common shares outstanding-basic and diluted
            </th>
            <td headers="threemonths 3m2021" className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  {formatCurrency(
                    isData['Weighted average common shares outstanding-basic and diluted'][threeMonth2021ColName]
                  )}
                </div>
              </div>
            </td>
            <td headers="threemonths 3m2020" className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  {formatCurrency(
                    isData['Weighted average common shares outstanding-basic and diluted'][threeMonth2020ColName]
                  )}
                </div>
              </div>
            </td>
            <td headers="sixmonths 6m2021" className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  {formatCurrency(
                    isData['Weighted average common shares outstanding-basic and diluted'][sixMonth2021ColName]
                  )}
                </div>
              </div>
            </td>
            <td headers="sixmonths 6m2020" className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  {formatCurrency(
                    isData['Weighted average common shares outstanding-basic and diluted'][sixMonth2020ColName]
                  )}
                </div>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </StyledTable>
  )
}

const BsTable = data => {
  const bsData = data.data

  return (
    <StyledTable
      summary="Column one has the asset or liability, other columns show the amounts by date period"
      id="assets_liabilities"
      className="financial-table mt-4"
      border="0"
      cellpadding="0"
      cellspacing="0"
    >
      <thead>
        <tr>
          <td className="no-border" />
          <th scope="col" className="text-align-center no-border no-wrap">
            June 30,
            <br /> 2021
          </th>
          <th scope="col" className="text-align-center no-border no-wrap">
            December 31,
            <br /> 2020
          </th>
        </tr>

        <tr>
          <td role="presentation" className="no-border" />
          <td role="presentation" className="no-border">
            <div className="subtotal full-width" />
          </td>
          <td role="presentation" className="no-border">
            <div className="subtotal full-width" />
          </td>
        </tr>

        <tr>
          <td colSpan="3" className="text-align-left no-wrap">
            ( In thousands, except per share data )
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th id="assets" colSpan="3" scope="colgroup" className="large-header">
            ASSETS
          </th>
        </tr>
        <tr>
          <th id="current_assets" colSpan="3" scope="colgroup" headers="assets" className="indent">
            Current assets:
          </th>
        </tr>

        {bsData.Cash && (
          <tr>
            <th headers="assets current_assets" className="text-header indent">
              Cash
            </th>
            <td headers="assets current_assets" className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(bsData.Cash[june2021ColName])}
            </td>
            <td headers="assets current_assets" className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(bsData.Cash[dec2020ColName])}
            </td>
          </tr>
        )}

        {bsData['Accounts receivable, net of allowance for doubtful accounts of $146 and $79, respectively'] && (
          <tr>
            <th headers="assets current_assets" className="text-header indent">
              Accounts receivable, net of allowance for doubtful accounts of $146 and $79, respectively
            </th>
            <td headers="assets current_assets" className="text-align-right">
              {formatCurrency(
                bsData['Accounts receivable, net of allowance for doubtful accounts of $146 and $79, respectively'][
                  june2021ColName
                ]
              )}
            </td>
            <td headers="assets current_assets" className="text-align-right">
              {formatCurrency(
                bsData['Accounts receivable, net of allowance for doubtful accounts of $146 and $79, respectively'][
                  dec2020ColName
                ]
              )}
            </td>
          </tr>
        )}

        {bsData['Unbilled receivables'] && (
          <tr>
            <th headers="assets current_assets" className="text-header indent">
              Unbilled receivables
            </th>
            <td headers="assets current_assets" className="text-align-right">
              {formatCurrency(bsData['Unbilled receivables'][june2021ColName])}
            </td>
            <td headers="assets current_assets" className="text-align-right">
              {formatCurrency(bsData['Unbilled receivables'][dec2020ColName])}
            </td>
          </tr>
        )}

        {bsData['Deferred costs, short term'] && (
          <tr>
            <th headers="assets current_assets" className="text-header indent">
              Deferred costs, short term
            </th>
            <td headers="assets current_assets" className="text-align-right">
              {formatCurrency(bsData['Deferred costs, short term'][june2021ColName])}
            </td>
            <td headers="assets current_assets" className="text-align-right">
              {formatCurrency(bsData['Deferred costs, short term'][dec2020ColName])}
            </td>
          </tr>
        )}

        {bsData['Debt issuance costs, net'] && (
          <tr>
            <th headers="assets current_assets" className="text-header indent">
              Debt issuance costs, net
            </th>
            <td headers="assets current_assets" className="text-align-right">
              {formatCurrency(bsData['Debt issuance costs, net'][june2021ColName])}
            </td>
            <td headers="assets current_assets" className="text-align-right">
              {formatCurrency(bsData['Debt issuance costs, net'][dec2020ColName])}
            </td>
          </tr>
        )}

        {bsData['Prepaid expenses and other current assets'] && (
          <tr>
            <th headers="assets current_assets" className="text-header indent">
              Prepaid expenses and other current assets
            </th>
            <td headers="assets current_assets" className="text-align-right no-border">
              {formatCurrency(bsData['Prepaid expenses and other current assets'][june2021ColName])}
            </td>
            <td headers="assets current_assets" className="text-align-right no-border">
              {formatCurrency(bsData['Prepaid expenses and other current assets'][dec2020ColName])}
            </td>
          </tr>
        )}

        {bsData['Total current assets'] && (
          <tr>
            <th headers="assets current_assets" className="text-header indent">
              Total current assets
            </th>
            <td headers="assets current_assets" className="text-align-right">
              <div className="subtotal">{formatCurrency(bsData['Total current assets'][june2021ColName])}</div>
            </td>
            <td headers="assets current_assets" className="text-align-right">
              <div className="subtotal">{formatCurrency(bsData['Total current assets'][dec2020ColName])}</div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="3" role="presentation" className="spacer" />
        </tr>

        {bsData['Property and equipment, net of accumulated depreciation of $244 and $209, respectively'] && (
          <tr>
            <th headers="assets current_assets" className="text-header indent">
              Property and equipment, net of accumulated depreciation of $244 and $209, respectively
            </th>
            <td headers="assets current_assets" className="text-align-right">
              {formatCurrency(
                bsData['Property and equipment, net of accumulated depreciation of $244 and $209, respectively'][
                  june2021ColName
                ]
              )}
            </td>
            <td headers="assets current_assets" className="text-align-right">
              {formatCurrency(
                bsData['Property and equipment, net of accumulated depreciation of $244 and $209, respectively'][
                  dec2020ColName
                ]
              )}
            </td>
          </tr>
        )}

        {bsData['Right of use assets'] && (
          <tr>
            <th headers="assets current_assets" className="text-header indent">
              Right of use assets
            </th>
            <td headers="assets current_assets" className="text-align-right">
              {formatCurrency(bsData['Right of use assets'][june2021ColName])}
            </td>
            <td headers="assets current_assets" className="text-align-right">
              {formatCurrency(bsData['Right of use assets'][dec2020ColName])}
            </td>
          </tr>
        )}

        {bsData['Deferred costs, long term'] && (
          <tr>
            <th headers="assets current_assets" className="text-header indent">
              Deferred costs, long term
            </th>
            <td headers="assets current_assets" className="text-align-right">
              {formatCurrency(bsData['Deferred costs, long term'][june2021ColName])}
            </td>
            <td headers="assets current_assets" className="text-align-right">
              {formatCurrency(bsData['Deferred costs, long term'][dec2020ColName])}
            </td>
          </tr>
        )}

        {bsData['Intangible assets, net of accumulated amortization of $4,876 and $4,328, respectively'] && (
          <tr>
            <th headers="assets current_assets" className="text-header indent">
              Intangible assets, net of accumulated amortization of $4,876 and $4,328, respectively
            </th>
            <td headers="assets current_assets" className="text-align-right">
              {formatCurrency(
                bsData['Intangible assets, net of accumulated amortization of $4,876 and $4,328, respectively'][
                  june2021ColName
                ]
              )}
            </td>
            <td headers="assets current_assets" className="text-align-right">
              {formatCurrency(
                bsData['Intangible assets, net of accumulated amortization of $4,876 and $4,328, respectively'][
                  dec2020ColName
                ]
              )}
            </td>
          </tr>
        )}

        {bsData.Goodwill && (
          <tr>
            <th headers="assets current_assets" className="text-header indent">
              Goodwill
            </th>
            <td headers="assets current_assets" className="text-align-right no-border">
              {formatCurrency(bsData.Goodwill[june2021ColName])}
            </td>
            <td headers="assets current_assets" className="text-align-right no-border">
              {formatCurrency(bsData.Goodwill[dec2020ColName])}
            </td>
          </tr>
        )}

        {bsData['Total assets'] && (
          <tr>
            <th headers="assets current_assets" className="text-header indent">
              Total assets
            </th>
            <td headers="assets current_assets" className="text-align-right no-border">
              <div className="subtotal">
                <div className="total">
                  <div className="total2">
                    <span className="mr-2">$</span>
                    {formatCurrency(bsData['Total assets'][june2021ColName])}
                  </div>
                </div>
              </div>
            </td>
            <td headers="assets current_assets" className="text-align-right no-border">
              <div className="subtotal">
                <div className="total">
                  <div className="total2">
                    <span className="mr-2">$</span>
                    {formatCurrency(bsData['Total assets'][dec2020ColName])}
                  </div>
                </div>
              </div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="3" role="presentation" className="spacer" />
        </tr>

        <tr>
          <th id="liabilities" colSpan="3" scope="colgroup" className="large-header">
            LIABILITIES AND STOCKHOLDERS' EQUITY
          </th>
        </tr>
        <tr>
          <th id="current_liabilities" colSpan="3" scope="colgroup" headers="liabilities" className="indent">
            Current liabilities:
          </th>
        </tr>

        {bsData['Accounts payable and accrued expenses'] && (
          <tr>
            <th headers="liabilities current_liabilities" className="text-header indent">
              Accounts payable and accrued expenses
            </th>
            <td headers="liabilities current_liabilities" className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(bsData['Accounts payable and accrued expenses'][june2021ColName])}
            </td>
            <td headers="liabilities current_liabilities" className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(bsData['Accounts payable and accrued expenses'][dec2020ColName])}
            </td>
          </tr>
        )}

        {bsData['Finance lease liabilities'] && (
          <tr>
            <th headers="liabilities current_liabilities" className="text-header indent">
              Finance lease liabilities
            </th>
            <td headers="liabilities current_liabilities" className="text-align-right">
              {formatCurrency(bsData['Finance lease liabilities'][june2021ColName])}
            </td>
            <td headers="liabilities current_liabilities" className="text-align-right">
              {formatCurrency(bsData['Finance lease liabilities'][dec2020ColName])}
            </td>
          </tr>
        )}

        {bsData['Operating lease liabilities'] && (
          <tr>
            <th headers="liabilities current_liabilities" className="text-header indent">
              Operating lease liabilities
            </th>
            <td headers="liabilities current_liabilities" className="text-align-right">
              {formatCurrency(bsData['Operating lease liabilities'][june2021ColName])}
            </td>
            <td headers="liabilities current_liabilities" className="text-align-right">
              {formatCurrency(bsData['Operating lease liabilities'][dec2020ColName])}
            </td>
          </tr>
        )}

        {bsData['Deferred revenue'] && (
          <tr>
            <th headers="liabilities current_liabilities" className="text-header indent">
              Deferred revenue
            </th>
            <td headers="liabilities current_liabilities" className="text-align-right">
              {formatCurrency(bsData['Deferred revenue'][june2021ColName])}
            </td>
            <td headers="liabilities current_liabilities" className="text-align-right">
              {formatCurrency(bsData['Deferred revenue'][dec2020ColName])}
            </td>
          </tr>
        )}

        {bsData['Term loan, short term'] && (
          <tr>
            <th headers="liabilities shortterm_liabilities" className="text-header indent">
              Term loan, short term
            </th>
            <td headers="liabilities shortterm_liabilities" className="text-align-right no-border">
              {formatCurrency(bsData['Term loan, short term'][june2021ColName])}
            </td>
            <td headers="liabilities shortterm_liabilities" className="text-align-right no-border">
              {formatCurrency(bsData['Term loan, short term'][dec2020ColName])}
            </td>
          </tr>
        )}

        {bsData['Total current liabilities'] && (
          <tr>
            <th headers="liabilities current_liabilities" className="text-header indent">
              Total current liabilities
            </th>
            <td headers="liabilities current_liabilities" className="text-align-right">
              <div className="subtotal" />
              {formatCurrency(bsData['Total current liabilities'][june2021ColName])}
            </td>
            <td headers="liabilities current_liabilities" className="text-align-right">
              <div className="subtotal" />
              {formatCurrency(bsData['Total current liabilities'][dec2020ColName])}
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="3" role="presentation" className="spacer" />
        </tr>

        <tr>
          <th id="longterm_liabilities" colSpan="3" scope="colgroup" headers="liabilities" className="indent">
            Long term liabilities:
          </th>
        </tr>

        {bsData['Finance lease liabilities_2'] && (
          <tr>
            <th headers="liabilities longterm_liabilities" className="text-header indent">
              Finance lease liabilities
            </th>
            <td headers="liabilities longterm_liabilities" className="text-align-right">
              {formatCurrency(bsData['Finance lease liabilities_2'][june2021ColName])}
            </td>
            <td headers="liabilities longterm_liabilities" className="text-align-right">
              {formatCurrency(bsData['Finance lease liabilities_2'][dec2020ColName])}
            </td>
          </tr>
        )}

        {bsData['Operating lease liabilities_2'] && (
          <tr>
            <th headers="liabilities longterm_liabilities" className="text-header indent">
              Operating lease liabilities
            </th>
            <td headers="liabilities longterm_liabilities" className="text-align-right">
              {formatCurrency(bsData['Operating lease liabilities_2'][june2021ColName])}
            </td>
            <td headers="liabilities longterm_liabilities" className="text-align-right">
              {formatCurrency(bsData['Operating lease liabilities_2'][dec2020ColName])}
            </td>
          </tr>
        )}

        {bsData['Deferred revenue_2'] && (
          <tr>
            <th headers="liabilities longterm_liabilities" className="text-header indent">
              Deferred revenue
            </th>
            <td headers="liabilities longterm_liabilities" className="text-align-right">
              {formatCurrency(bsData['Deferred revenue_2'][june2021ColName])}
            </td>
            <td headers="liabilities longterm_liabilities" className="text-align-right">
              {formatCurrency(bsData['Deferred revenue_2'][dec2020ColName])}
            </td>
          </tr>
        )}

        {bsData['Term loan, long term'] && (
          <tr>
            <th headers="liabilities longterm_liabilities" className="text-header indent">
              Term loan, long term
            </th>
            <td headers="liabilities longterm_liabilities" className="text-align-right no-border">
              {formatCurrency(bsData['Term loan, long term'][june2021ColName])}
            </td>
            <td headers="liabilities longterm_liabilities" className="text-align-right no-border">
              {formatCurrency(bsData['Term loan, long term'][dec2020ColName])}
            </td>
          </tr>
        )}

        {bsData['Total liabilities'] && (
          <tr>
            <th headers="liabilities" className="text-header indent">
              Total liabilities
            </th>
            <td headers="liabilities" className="text-align-right">
              <div className="subtotal">{formatCurrency(bsData['Total liabilities'][june2021ColName])}</div>
            </td>
            <td headers="liabilities" className="text-align-right">
              <div className="subtotal">{formatCurrency(bsData['Total liabilities'][dec2020ColName])}</div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="3" role="presentation" className="spacer" />
        </tr>

        <tr>
          <th id="stockholders_equity" scope="colgroup" headers="liabilities" className="indent">
            <div>Stockholders' equity:</div>
            <div className="text-weight-book">Preferred stock, $0.00001 par value, 10,000 shares authorized</div>
          </th>
        </tr>

        {bsData[
          'Series A Convertible Preferred Stock, $0.00001 par value, 200 shares designated, zero and 90 shares issued and outstanding as of June 30, 2021 and December 31, 2020, respectively'
        ] && (
          <tr>
            <th headers="liabilities stockholders_equity" className="text-header indent w-60">
              <div>Series A Convertible Preferred Stock,</div>
              <div className="text-weight-book">
                $0.00001 par value, 200 shares designated, zero and 90 shares issued and outstanding as of June 30, 2021
                and December 31, 2020, respectively
              </div>
            </th>
            <td headers="liabilities stockholders_equity" className="text-align-right">
              {formatCurrency(
                bsData[
                  'Series A Convertible Preferred Stock, $0.00001 par value, 200 shares designated, zero and 90 shares issued and outstanding as of June 30, 2021 and December 31, 2020, respectively'
                ][june2021ColName]
              )}
            </td>
            <td headers="liabilities stockholders_equity" className="text-align-right">
              {formatCurrency(
                bsData[
                  'Series A Convertible Preferred Stock, $0.00001 par value, 200 shares designated, zero and 90 shares issued and outstanding as of June 30, 2021 and December 31, 2020, respectively'
                ][dec2020ColName]
              )}
            </td>
          </tr>
        )}

        {bsData[
          'Common stock, $0.00001 par value, 50,000 shares authorized, 11,277 and 10,130 shares issued and outstanding as of June 30, 2021 and December 31, 2020, respectively'
        ] && (
          <tr>
            <th headers="liabilities stockholders_equity" className="text-header indent w-60">
              <div>Common stock,</div>
              <div className="text-weight-book">
                $0.00001 par value, 50,000 shares authorized, 11,277 and 10,130 shares issued and outstanding as of June
                30, 2021 and December 31, 2020, respectively
              </div>
            </th>
            <td headers="liabilities stockholders_equity" className="text-align-right">
              {formatCurrency(
                bsData[
                  'Common stock, $0.00001 par value, 50,000 shares authorized, 11,277 and 10,130 shares issued and outstanding as of June 30, 2021 and December 31, 2020, respectively'
                ][june2021ColName]
              )}
            </td>
            <td headers="liabilities stockholders_equity" className="text-align-right">
              {formatCurrency(
                bsData[
                  'Common stock, $0.00001 par value, 50,000 shares authorized, 11,277 and 10,130 shares issued and outstanding as of June 30, 2021 and December 31, 2020, respectively'
                ][dec2020ColName]
              )}
            </td>
          </tr>
        )}

        {bsData['Additional paid-in capital'] && (
          <tr>
            <th headers="liabilities stockholders_equity" className="text-header indent">
              Additional paid-in capital
            </th>
            <td headers="liabilities stockholders_equity" className="text-align-right">
              {formatCurrency(bsData['Additional paid-in capital'][june2021ColName])}
            </td>
            <td headers="liabilities stockholders_equity" className="text-align-right">
              {formatCurrency(bsData['Additional paid-in capital'][dec2020ColName])}
            </td>
          </tr>
        )}

        {bsData['Accumulated deficit'] && (
          <tr>
            <th headers="liabilities stockholders_equity" className="text-header indent">
              Accumulated deficit
            </th>
            <td
              headers="liabilities stockholders_equity"
              className="text-align-right no-border"
              aria-label="negative value"
            >
              ({formatCurrency(bsData['Accumulated deficit'][june2021ColName])})
            </td>
            <td
              headers="liabilities stockholders_equity"
              className="text-align-right no-border"
              aria-label="negative value"
            >
              ({formatCurrency(bsData['Accumulated deficit'][dec2020ColName])})
            </td>
          </tr>
        )}

        {bsData["Total stockholders' equity"] && (
          <tr>
            <th headers="liabilities stockholders_equity" className="text-header indent">
              Total stockholders' equity
            </th>
            <td headers="liabilities stockholders_equity" className="text-align-right no-border">
              <div className="subtotal">{formatCurrency(bsData["Total stockholders' equity"][june2021ColName])}</div>
            </td>
            <td headers="liabilities stockholders_equity" className="text-align-right no-border">
              <div className="subtotal">{formatCurrency(bsData["Total stockholders' equity"][dec2020ColName])}</div>
            </td>
          </tr>
        )}

        <tr className="spacer-row-subtotal">
          <td role="presentation" />
          <td role="presentation">
            <div className="subtotal">&nbsp;</div>
          </td>
          <td role="presentation">
            <div className="subtotal">&nbsp;</div>
          </td>
        </tr>

        {bsData["Total liabilities and stockholders' equity"] && (
          <tr>
            <th headers="liabilities stockholders_equity" className="text-header indent">
              Total liabilities and stockholders' equity
            </th>
            <td headers="liabilities stockholders_equity" className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>
                  {formatCurrency(bsData["Total liabilities and stockholders' equity"][june2021ColName])}
                </div>
              </div>
            </td>
            <td headers="liabilities stockholders_equity" className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>
                  {formatCurrency(bsData["Total liabilities and stockholders' equity"][dec2020ColName])}
                </div>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </StyledTable>
  )
}

const GaapTable = data => {
  const gaapData = data.data

  return (
    <>
      <StyledTable
        summary="Column one has the non-GAAP earnings loss source, other columns show the amounts by date period"
        id="ae_revenue"
        className="financial-table"
        border="0"
        cellpadding="0"
        cellspacing="0"
      >
        <thead>
          <tr>
            <td className="no-border" />
            <th colSpan="3" id="threemonths" scope="col" className="text-align-center no-wrap no-border">
              Three months ended
              <br />
              June 30,
            </th>
            <th colSpan="3" id="sixmonths" scope="col" className="text-align-center no-wrap no-border">
              Six months ended
              <br />
              June 30,
            </th>
          </tr>
          <tr>
            <td className="no-border" />
            <th id="3m2021" scope="col" colSpan="3" className="no-border no-wrap">
              <div className="subtotal full-width space-between">
                <div style={{ textAlign: 'center' }}>2021</div>
                <div style={{ textAlign: 'center' }}>2020</div>
              </div>
            </th>
            <th id="6m2021" scope="col" colSpan="3" className="no-border no-wrap">
              <div className="subtotal full-width space-between">
                <div style={{ textAlign: 'center' }}>2021</div>
                <div style={{ textAlign: 'center' }}>2020</div>
              </div>
            </th>
          </tr>
          <tr>
            <td colSpan="6" className="text-align-left">
              ( In thousands, except per share data )
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th id="assets" colSpan="6" scope="colgroup" className="large-header">
              Non-GAAP Earnings (Loss) Reconciliation
            </th>
          </tr>
          {gaapData['Net loss (GAAP)'] && (
            <tr>
              <th id="net-loss-gaap" className="text-header" colSpan="2">
                Net loss (GAAP)
              </th>
              <td headers="threemonths 3m2021 net-loss-gaap" className="text-align-right" aria-label="negative value">
                <span className="mr-2">$</span>({formatCurrency(gaapData['Net loss (GAAP)'][threeMonth2021ColName])})
              </td>
              <td headers="threemonths 3m2020 net-loss-gaap" className="text-align-right" aria-label="negative value">
                <span className="mr-2">$</span>({formatCurrency(gaapData['Net loss (GAAP)'][threeMonth2020ColName])})
              </td>
              <td headers="sixmonths 6m2021 net-loss-gaap" className="text-align-right" aria-label="negative value">
                <span className="mr-2">$</span>({formatCurrency(gaapData['Net loss (GAAP)'][sixMonth2021ColName])})
              </td>
              <td headers="sixmonths 6m2020 net-loss-gaap" className="text-align-right" aria-label="negative value">
                <span className="mr-2">$</span>({formatCurrency(gaapData['Net loss (GAAP)'][sixMonth2020ColName])})
              </td>
            </tr>
          )}

          {gaapData['Non-cash valuation adjustments to liabilities'] && (
            <tr>
              <th className="text-header" colSpan="2">
                Non-cash valuation adjustments to liabilities
              </th>
              <td headers="threemonths 3m2021" className="text-align-right" aria-label="negative value">
                {formatCurrency(gaapData['Non-cash valuation adjustments to liabilities'][threeMonth2021ColName])}
              </td>
              <td headers="threemonths 3m2020" className="text-align-right">
                {formatCurrency(gaapData['Non-cash valuation adjustments to liabilities'][threeMonth2020ColName])}
              </td>
              <td headers="sixmonths 6m2021" className="text-align-right" aria-label="negative value">
                {formatCurrency(gaapData['Non-cash valuation adjustments to liabilities'][sixMonth2021ColName])}
              </td>
              <td headers="sixmonths 6m2020" className="text-align-right">
                {formatCurrency(gaapData['Non-cash valuation adjustments to liabilities'][sixMonth2020ColName])}
              </td>
            </tr>
          )}

          {gaapData['Interest expense'] && (
            <tr>
              <th className="text-header" colSpan="2">
                Interest expense
              </th>
              <td headers="threemonths 3m2021 interest-expense" className="text-align-right">
                {formatCurrency(gaapData['Interest expense'][threeMonth2021ColName])}
              </td>
              <td headers="threemonths 3m2020 interest-expense" className="text-align-right">
                {formatCurrency(gaapData['Interest expense'][threeMonth2020ColName])}
              </td>
              <td headers="sixmonths 6m2021 interest-expense" className="text-align-right">
                {formatCurrency(gaapData['Interest expense'][sixMonth2021ColName])}
              </td>
              <td headers="sixmonths 6m2020 interest-expense" className="text-align-right">
                {formatCurrency(gaapData['Interest expense'][sixMonth2020ColName])}
              </td>
            </tr>
          )}

          {gaapData['Stock-based compensation expense'] && (
            <tr>
              <th className="text-header" colSpan="2">
                Stock-based compensation expense
              </th>
              <td headers="threemonths 3m2021" className="text-align-right">
                {formatCurrency(gaapData['Stock-based compensation expense'][threeMonth2021ColName])}
              </td>
              <td headers="threemonths 3m2020" className="text-align-right">
                {formatCurrency(gaapData['Stock-based compensation expense'][threeMonth2020ColName])}
              </td>
              <td headers="sixmonths 6m2021" className="text-align-right">
                {formatCurrency(gaapData['Stock-based compensation expense'][sixMonth2021ColName])}
              </td>
              <td headers="sixmonths 6m2020" className="text-align-right">
                {formatCurrency(gaapData['Stock-based compensation expense'][sixMonth2020ColName])}
              </td>
            </tr>
          )}

          {gaapData['Loss on impairment of long-lived assets'] && (
            <tr>
              <th className="text-header" colSpan="2">
                Loss on impairment of long-lived assets
              </th>
              <td headers="threemonths 3m2021" className="text-align-right">
                {formatCurrency(gaapData['Loss on impairment of long-lived assets'][threeMonth2021ColName])}
              </td>
              <td headers="threemonths 3m2020" className="text-align-right">
                {formatCurrency(gaapData['Loss on impairment of long-lived assets'][threeMonth2020ColName])}
              </td>
              <td headers="sixmonths 6m2021" className="text-align-right">
                {formatCurrency(gaapData['Loss on impairment of long-lived assets'][sixMonth2021ColName])}
              </td>
              <td headers="sixmonths 6m2020" className="text-align-right">
                {formatCurrency(gaapData['Loss on impairment of long-lived assets'][sixMonth2020ColName])}
              </td>
            </tr>
          )}

          {gaapData['Loss on disposal of property and equipment'] && (
            <tr>
              <th className="text-header" colSpan="2">
                Loss on disposal of property and equipment
              </th>
              <td headers="threemonths 3m2021" className="text-align-right">
                {formatCurrency(gaapData['Loss on disposal of property and equipment'][threeMonth2021ColName])}
              </td>
              <td headers="threemonths 3m2020" className="text-align-right">
                {formatCurrency(gaapData['Loss on disposal of property and equipment'][threeMonth2020ColName])}
              </td>
              <td headers="sixmonths 6m2021" className="text-align-right">
                {formatCurrency(gaapData['Loss on disposal of property and equipment'][sixMonth2021ColName])}
              </td>
              <td headers="sixmonths 6m2020" className="text-align-right">
                {formatCurrency(gaapData['Loss on disposal of property and equipment'][sixMonth2020ColName])}
              </td>
            </tr>
          )}

          {gaapData['Gain on loan forgiveness'] && (
            <tr>
              <th className="text-header" colSpan="2">
                Gain on loan forgiveness
              </th>
              <td headers="threemonths 3m2021" className="text-align-right">
                ({formatCurrency(gaapData['Gain on loan forgiveness'][threeMonth2021ColName])})
              </td>
              <td headers="threemonths 3m2020" className="text-align-right">
                {formatCurrency(gaapData['Gain on loan forgiveness'][threeMonth2020ColName])}
              </td>
              <td headers="sixmonths 6m2021" className="text-align-right">
                ({formatCurrency(gaapData['Gain on loan forgiveness'][sixMonth2021ColName])})
              </td>
              <td headers="sixmonths 6m2020" className="text-align-right">
                {formatCurrency(gaapData['Gain on loan forgiveness'][sixMonth2020ColName])}
              </td>
            </tr>
          )}

          {gaapData['Non-GAAP loss'] && (
            <tr>
              <th className="text-header" colSpan="2">
                Non-GAAP loss
              </th>
              <td headers="threemonths 3m2021" className="text-align-right no-border" aria-label="negative value">
                <div className="total">
                  <div className="total2">
                    <div className="subtotal">
                      <span className="mr-2">$</span>({formatCurrency(gaapData['Non-GAAP loss'][threeMonth2021ColName])}
                      )
                    </div>
                  </div>
                </div>
              </td>
              <td headers="threemonths 3m2020" className="text-align-right no-border" aria-label="negative value">
                <div className="total">
                  <div className="total2">
                    <div className="subtotal">
                      <span className="mr-2">$</span>({formatCurrency(gaapData['Non-GAAP loss'][threeMonth2020ColName])}
                      )
                    </div>
                  </div>
                </div>
              </td>
              <td headers="sixmonths 6m2021" className="text-align-right no-border" aria-label="negative value">
                <div className="total">
                  <div className="total2">
                    <div className="subtotal">
                      <span className="mr-2">$</span>({formatCurrency(gaapData['Non-GAAP loss'][sixMonth2021ColName])})
                    </div>
                  </div>
                </div>
              </td>
              <td headers="sixmonths 6m2020" className="text-align-right no-border" aria-label="negative value">
                <div className="total">
                  <div className="total2">
                    <div className="subtotal">
                      <span className="mr-2">$</span>({formatCurrency(gaapData['Non-GAAP loss'][sixMonth2020ColName])})
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          )}

          <tr>
            <td role="presentation" className="spacer" />
          </tr>

          <tr>
            <th id="assets" colSpan="6" scope="colgroup" className="large-header">
              Non-GAAP Earnings (Loss) per Diluted Share Reconciliation
            </th>
          </tr>

          {gaapData['Net loss per common share (GAAP) — diluted'] && (
            <tr>
              <th className="text-header" colSpan="2">
                Net loss per common share (GAAP) — diluted
              </th>
              <td headers="threemonths 3m2021" className="text-align-right" aria-label="negative value">
                <div>
                  <span className="mr-2">$</span>(
                  {formatCurrency(gaapData['Net loss per common share (GAAP) — diluted'][threeMonth2021ColName])})
                </div>
              </td>
              <td headers="threemonths 3m2020" className="text-align-right" aria-label="negative value">
                <div>
                  <span className="mr-2">$</span>(
                  {formatCurrency(gaapData['Net loss per common share (GAAP) — diluted'][threeMonth2020ColName])})
                </div>
              </td>
              <td headers="sixmonths 6m2021" className="text-align-right" aria-label="negative value">
                <div>
                  <span className="mr-2">$</span>(
                  {formatCurrency(gaapData['Net loss per common share (GAAP) — diluted'][sixMonth2021ColName])})
                </div>
              </td>
              <td headers="sixmonths 6m2020" className="text-align-right" aria-label="negative value">
                <div>
                  <span className="mr-2">$</span>(
                  {formatCurrency(gaapData['Net loss per common share (GAAP) — diluted'][sixMonth2020ColName])})
                </div>
              </td>
            </tr>
          )}

          {gaapData['Non-cash valuation adjustments to liabilities - 2'] && (
            <tr>
              <th className="text-header" colSpan="2">
                Non-cash valuation adjustments to liabilities
              </th>
              <td headers="threemonths 3m2021" className="text-align-right" aria-label="negative value">
                {formatCurrency(gaapData['Non-cash valuation adjustments to liabilities - 2'][threeMonth2021ColName])}
              </td>
              <td headers="threemonths 3m2020" className="text-align-right">
                {formatCurrency(gaapData['Non-cash valuation adjustments to liabilities - 2'][threeMonth2020ColName])}
              </td>
              <td headers="sixmonths 6m2021" className="text-align-right" aria-label="negative value">
                {formatCurrency(gaapData['Non-cash valuation adjustments to liabilities - 2'][sixMonth2021ColName])}
              </td>
              <td headers="sixmonths 6m2020" className="text-align-right">
                {formatCurrency(gaapData['Non-cash valuation adjustments to liabilities - 2'][sixMonth2020ColName])}
              </td>
            </tr>
          )}

          {gaapData['Interest expense - 2'] && (
            <tr>
              <th className="text-header" colSpan="2">
                Interest expense
              </th>
              <td headers="threemonths 3m2021" className="text-align-right">
                {formatCurrency(gaapData['Interest expense - 2'][threeMonth2021ColName])}
              </td>
              <td headers="threemonths 3m2020" className="text-align-right">
                {formatCurrency(gaapData['Interest expense - 2'][threeMonth2020ColName])}
              </td>
              <td headers="sixmonths 6m2021" className="text-align-right">
                {formatCurrency(gaapData['Interest expense - 2'][sixMonth2021ColName])}
              </td>
              <td headers="sixmonths 6m2020" className="text-align-right">
                {formatCurrency(gaapData['Interest expense - 2'][sixMonth2020ColName])}
              </td>
            </tr>
          )}

          {gaapData['Stock-based compensation expense - 2'] && (
            <tr>
              <th className="text-header" colSpan="2">
                Stock-based compensation expense
              </th>
              <td headers="threemonths 3m2021" className="text-align-right">
                {formatCurrency(gaapData['Stock-based compensation expense - 2'][threeMonth2021ColName])}
              </td>
              <td headers="threemonths 3m2020" className="text-align-right">
                {formatCurrency(gaapData['Stock-based compensation expense - 2'][threeMonth2020ColName])}
              </td>
              <td headers="sixmonths 6m2021" className="text-align-right">
                {formatCurrency(gaapData['Stock-based compensation expense - 2'][sixMonth2021ColName])}
              </td>
              <td headers="sixmonths 6m2020" className="text-align-right">
                {formatCurrency(gaapData['Stock-based compensation expense - 2'][sixMonth2020ColName])}0
              </td>
            </tr>
          )}

          {gaapData['Loss on impairment of long-lived assets - 1'] && (
            <tr>
              <th className="text-header" colSpan="2">
                Loss on impairment of long-lived assets
              </th>
              <td headers="threemonths 3m2021" className="text-align-right">
                {formatCurrency(gaapData['Loss on impairment of long-lived assets - 1'][threeMonth2021ColName])}
              </td>
              <td headers="threemonths 3m2020" className="text-align-right">
                {formatCurrency(gaapData['Loss on impairment of long-lived assets - 1'][threeMonth2020ColName])}
              </td>
              <td headers="sixmonths 6m2021" className="text-align-right">
                {formatCurrency(gaapData['Loss on impairment of long-lived assets - 1'][sixMonth2021ColName])}
              </td>
              <td headers="sixmonths 6m2020" className="text-align-right">
                {formatCurrency(gaapData['Loss on impairment of long-lived assets - 1'][sixMonth2020ColName])}
              </td>
            </tr>
          )}

          {gaapData['Loss on disposal of property and equipment - 2'] && (
            <tr>
              <th className="text-header" colSpan="2">
                Loss on disposal of property and equipment
              </th>
              <td headers="threemonths 3m2021" className="text-align-right">
                {formatCurrency(gaapData['Loss on disposal of property and equipment - 2'][threeMonth2021ColName])}
              </td>
              <td headers="threemonths 3m2020" className="text-align-right">
                {formatCurrency(gaapData['Loss on disposal of property and equipment - 2'][threeMonth2020ColName])}
              </td>
              <td headers="sixmonths 6m2021" className="text-align-right">
                {formatCurrency(gaapData['Loss on disposal of property and equipment - 2'][sixMonth2021ColName])}
              </td>
              <td headers="sixmonths 6m2020" className="text-align-right">
                {formatCurrency(gaapData['Loss on disposal of property and equipment - 2'][sixMonth2020ColName])}
              </td>
            </tr>
          )}

          {gaapData['Gain on loan forgiveness - 2'] && (
            <tr>
              <th className="text-header" colSpan="2">
                Gain on loan forgiveness
              </th>
              <td headers="threemonths 3m2021" className="text-align-right">
                ({formatCurrency(gaapData['Gain on loan forgiveness - 2'][threeMonth2021ColName])})
              </td>
              <td headers="threemonths 3m2020" className="text-align-right">
                {formatCurrency(gaapData['Gain on loan forgiveness - 2'][threeMonth2020ColName])}
              </td>
              <td headers="sixmonths 6m2021" className="text-align-right">
                ({formatCurrency(gaapData['Gain on loan forgiveness - 2'][sixMonth2021ColName])})
              </td>
              <td headers="sixmonths 6m2020" className="text-align-right">
                {formatCurrency(gaapData['Gain on loan forgiveness - 2'][sixMonth2020ColName])}
              </td>
            </tr>
          )}

          {gaapData['Non-GAAP loss per diluted share (1)'] && (
            <tr>
              <th className="text-header" colSpan="2" style={{ paddingRight: '0px', fontSize: '95%' }}>
                Non-GAAP loss per diluted share (1)
              </th>
              <td
                headers="threemonths 3m2021"
                className="text-align-right no-border"
                style={{ paddingLeft: '0px' }}
                aria-label="negative value"
              >
                <div className="total">
                  <div className="total2">
                    <div className="subtotal">
                      <span className="mr-2">$</span>(
                      {formatCurrency(gaapData['Non-GAAP loss per diluted share (1)'][threeMonth2021ColName])})
                    </div>
                  </div>
                </div>
              </td>
              <td headers="threemonths 3m2020" className="text-align-right no-border" aria-label="negative value">
                <div className="total">
                  <div className="total2">
                    <div className="subtotal">
                      <span className="mr-2">$</span>(
                      {formatCurrency(gaapData['Non-GAAP loss per diluted share (1)'][threeMonth2020ColName])})
                    </div>
                  </div>
                </div>
              </td>
              <td headers="sixmonths 6m2021" className="text-align-right no-border" aria-label="negative value">
                <div className="total">
                  <div className="total2">
                    <div className="subtotal">
                      <span className="mr-2">$</span>(
                      {formatCurrency(gaapData['Non-GAAP loss per diluted share (1)'][sixMonth2021ColName])})
                    </div>
                  </div>
                </div>
              </td>
              <td headers="sixmonths 6m2020" className="text-align-right no-border" aria-label="negative value">
                <div className="total">
                  <div className="total2">
                    <div className="subtotal">
                      <span className="mr-2">$</span>(
                      {formatCurrency(gaapData['Non-GAAP loss per diluted share (1)'][sixMonth2020ColName])})
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          )}

          <tr>
            <td role="presentation" className="spacer no-border" />
          </tr>

          {gaapData['Diluted weighted average shares (2)'] && (
            <tr>
              <th className="text-header" colSpan="2">
                Diluted weighted average shares (2)
              </th>
              <td headers="threemonths 3m2021" className="text-align-right">
                <div className="total">
                  {formatCurrency(gaapData['Diluted weighted average shares (2)'][threeMonth2021ColName])}
                </div>
              </td>
              <td headers="threemonths 3m2020" className="text-align-right">
                <div className="total">
                  {formatCurrency(gaapData['Diluted weighted average shares (2)'][threeMonth2020ColName])}
                </div>
              </td>
              <td headers="sixmonths 6m2021" className="text-align-right">
                <div className="total">
                  {formatCurrency(gaapData['Diluted weighted average shares (2)'][sixMonth2021ColName])}
                </div>
              </td>
              <td headers="sixmonths 6m2020" className="text-align-right">
                <div className="total">
                  {formatCurrency(gaapData['Diluted weighted average shares (2)'][sixMonth2020ColName])}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </StyledTable>
      <ol style={{ marginTop: '24px', marginLeft: '0px', listStyleType: 'none' }}>
        <li>
          (1) Non-GAAP earnings per adjusted diluted share for our common stock is computed using the more dilutive of
          the two-class method or the if-converted method.
        </li>
        <li>
          (2) The number of diluted weighted average shares used for this calculation is the same as the weighted
          average common shares outstanding share count when the Company reports a GAAP and non-GAAP net loss.
        </li>
      </ol>
    </>
  )
}

const StyledEarningsReport = styled.div`
  margin: 100px 0 0;
`

const checkExpiredData = timestamp => {
  const dayMs = 1000 * 60 * 60 * 24
  const dayAgo = Date.now() - dayMs
  return timestamp > dayAgo
}

const EarningsReport = () => {
  const [bsData, setBsData] = useState({})
  const [isData, setIsData] = useState({})
  const [gaapData, setGaapData] = useState({})

  const fetchTableData = tableName =>
    new Promise(resolve => {
      const data = {}
      // if no / expired data exists then query AirTable
      AIRTABLE_BASE(tableName)
        .select({
          maxRecords: 1000,
          pageSize: 100,
          view: 'Grid view',
        })
        .eachPage(
          (records, fetchNextPage) => {
            // This function (`page`) will get called for each page of records.
            records.forEach(record => {
              let label = record.fields[labelColName]
              if (data[label]) {
                console.warn('Multiple earnings results with same name: ', label)
                label += '_2'
              }

              if (tableName === 'BS') {
                data[label] = {
                  [june2021ColName]: record.fields[june2021ColName],
                  [dec2020ColName]: record.fields[dec2020ColName],
                }
              } else if (tableName === 'IS') {
                data[label] = {
                  [threeMonth2021ColName]: record.fields[threeMonth2021ColName],
                  [threeMonth2020ColName]: record.fields[threeMonth2020ColName],
                  [sixMonth2021ColName]: record.fields[sixMonth2021ColName],
                  [sixMonth2020ColName]: record.fields[sixMonth2020ColName],
                }
              } else if (tableName === 'Non-GAAP') {
                data[label] = {
                  [threeMonth2021ColName]: record.fields[threeMonth2021ColName],
                  [threeMonth2020ColName]: record.fields[threeMonth2020ColName],
                  [sixMonth2021ColName]: record.fields[sixMonth2021ColName],
                  [sixMonth2020ColName]: record.fields[sixMonth2020ColName],
                }
              }
            })

            // To fetch the next page of records, call `fetchNextPage`.
            // If there are more records, `page` will get called again.
            // If there are no more records, `done` will get called.
            fetchNextPage()
          },
          err => {
            // on finish
            if (err) {
              console.error(err)
              resolve({})
            } else {
              resolve(data)
            }
          }
        )
    })

  useEffect(async () => {
    // check local storage and timestamp for existing data
    const ls = getPersistedData()
    const lsEarningsData = ls['earnings-data-q3-2020']

    if (lsEarningsData && checkExpiredData(lsEarningsData.timestamp)) {
      // exists and is not expired
      setBsData(lsEarningsData.bsData)
      setIsData(lsEarningsData.isData)
      setGaapData(lsEarningsData.gaapData)
    } else {
      // Request AirTable base data
      const bsRes = await fetchTableData('BS')
      const isRes = await fetchTableData('IS')
      const gaapRes = await fetchTableData('Non-GAAP')
      // set data timestamp
      persistData(
        Object.assign(ls, {
          'earnings-data-q3-2020': {
            timestamp: Date.now(),
            bsData: bsRes,
            isData: isRes,
            gaapData: gaapRes,
          },
        })
      )
      setBsData(bsRes)
      setIsData(isRes)
      setGaapData(gaapRes)
    }
  }, [])

  return (
    <StyledEarningsReport>
      <Text center heading3 aria-level="2" role="heading">
        AUDIOEYE, INC.
        <br />
        STATEMENTS OF OPERATIONS <br />
        (unaudited)
      </Text>
      <Space height={60} />
      <IsTable data={isData} />
      <Space height={100} />
      <Text center heading3 aria-level="2" role="heading">
        AUDIOEYE, INC.
        <br />
        BALANCE SHEETS
        <br />
        (unaudited)
      </Text>
      <Space height={60} />
      <BsTable data={bsData} />
      <Space height={100} />
      <Text center heading3 aria-level="2" role="heading">
        AUDIOEYE, INC.
        <br />
        RECONCILIATIONS OF GAAP to NON-GAAP FINANCIAL MEASURES
        <br />
        (unaudited)
      </Text>
      <Space height={60} />
      <GaapTable data={gaapData} />
    </StyledEarningsReport>
  )
}

export default EarningsReport
